import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

// import quotebgbg from "assets/img/qt-bg-bg.png";
import { CssBaseline, Hidden, Typography } from "@material-ui/core";

import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";
import brandsImg from "assets/img/our-brands.png";
import ProductCarousel from "./ProductCarousel";
import CategoriesSection from "./CategoriesSection";

const dashboardRoutes = [];

class ProductsPage extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
                    // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
                    // brand="AL MIDIAFF"
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                    {...rest}
                />
                <CssBaseline />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section} style={{ padding: "0 15%" }}>
                        <div style={{ height: 160 }}></div>

                        <div className={classes.container}>
                            <GridContainer aria-label="breadcrumb">
                                <a href="/">Home /</a>
                                &nbsp;
                                <a href="#">Products</a>

                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={9} md={8} style={{}}>
                                    <h1 className={classes.title} style={{ color: "#6C648B" }}>Our Products</h1>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div style={{ height: 40 }}></div>
                        <div style={{ background: "#fff", maxWidth: "100%" }}>
                            <div className={classes.section} style={{}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} style={{}}>
                                        <div align="justify" className={classes.description}>
                                            <br />
                                            With a broad scope of versatile and mechanical level force
                                            instruments, AMICO is a significant provider and merchant
                                            of the best force gear of the main brands in the business.
                                            <br />
                                            <br />
                                            We Gives a one-stop answer for all organization bundling needs,
                                            as an enormous provisional of a wide scope of materials.
                                            <br />
                                            <br />
                                            With a tremendous scope of hardware from Power tools to Safety,
                                            guaranteeing the wellbeing of the entirety of our clients.
                                        </div>
                                    </GridItem>
                                    <Hidden xsDown>
                                        <GridItem xs={12} sm={12} md={8} style={{}}>
                                            <ProductCarousel />
                                        </GridItem>
                                    </Hidden>
                                </GridContainer>
                            </div>
                            <div style={{ height: 80 }}></div>
                            <div>
                                <CategoriesSection />
                            </div>
                            <div id="brands" style={{ height: 60 }}></div>
                            <div className={classes.section} style={{textAlign:"center"}}>
                                <h3 className={classes.title} style={{ color: "#6C648B" }}>Our Brands</h3>
                                <img
                                    src={brandsImg}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className={classes.section} style={{}}>

                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#6C648B", width: "100%" }}>
                        <div className={classes.container} >
                            <NewsLetterSection />
                        </div>
                    </div>

                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <FooterSection />

                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(landingPageStyle, productStyle)(ProductsPage);
